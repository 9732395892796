// src/MaintenanceBanner.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';


const MaintenanceBanner = ({ isVisible }) => {
    if (!isVisible) return null;


    return (
    <div className="bg-dark text-white text-center p-2" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '40px',
        zIndex: 9999,  // Set to a higher value
        marginBottom: '--30',
    }}>
        <p className='small mb-0'>This website is currently under maintenance. Please check back later.</p>
    </div>
);
};

export default MaintenanceBanner;
