import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Modal, Spinner } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdCheckCircle, MdError } from "react-icons/md"; // Import Icons
import logo2 from "./assets/images/Logo2.png";
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';

const Footer = ({ downloadRef }) => {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        address: '',
        description: ''
    });

    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showCookiesModal, setShowCookiesModal] = useState(false);
    const [showSecurityModal, setShowSecurityModal] = useState(false);

    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is filled
        if (!formData.name || !formData.surname || !formData.email || !formData.address || !formData.description) {
            setModalMessage("Please fill out all the required fields.");
            setIsSuccess(false);
            setShowModal(true);
            return;
        }

        setIsSubmitting(true);
        setShowModal(true);

        try {
           const response = await fetch('https://transweeft-website-backend.onrender.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setModalMessage("Your message has been received. We appreciate your feedback and will get back to you shortly.");
                setIsSuccess(true);
                // Reset form fields
                setFormData({ name: '', surname: '', email: '', address: '', description: '' });
            } else {
                setModalMessage("Failed to send message. Please try again later.");
                setIsSuccess(false);
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setModalMessage("An error occurred. Please check your network and try again.");
            setIsSuccess(false);
        }

        setIsSubmitting(false);
    };

    const handleCookiesClick = () => setShowCookiesModal(true);
    const handleSecurityClick = () => setShowSecurityModal(true);
    const handleCloseCookies = () => setShowCookiesModal(false);
    const handleCloseSecurity = () => setShowSecurityModal(false);

    return (
        <footer style={{ backgroundColor: "#000000", color: "white", padding: "40px 0" }}>
            <Container>
                {/* Main Footer Content */}
                <Row className="justify-content-between align-items-start">
                    {/* Contact Us Section */}
                    <Col xs={12} md={6} className="mb-4 order-2 order-md-1">
                        <div className="footer-logo d-flex justify-content-start mb-3">
                            <img src={logo2} alt="Transweeft Logo" className="footer-logo-image" style={{ width: "120px" }} />
                        </div>
                        <h2 style={{ color: "#AFAFAF", fontWeight: "bold" }}>Contact Us</h2>
                        <div className="d-flex flex-column align-items-md-start" style={{ fontSize: 'small' }}>
                            {/* Address */}
                            <div style={{ textAlign: "left", marginBottom: "15px" }}>
                                <strong>Address:</strong><br />
                                C22, Applewood Estate 3, Off Harris Drive, VGC, Lagos State.
                            </div>

                            {/* General Email */}
                            <div style={{ textAlign: "left", marginBottom: "15px" }}>
                                <strong>General Enquiries:</strong><br />
                                <a href="mailto:support@transweeft.com" style={{ color: "white", textDecoration: 'none' }}>info@transweeft.com</a>
                            </div>
                            {/* Support Email */}
                            <div style={{ textAlign: "left", marginBottom: "15px" }}>
                                <strong>Support Email:</strong><br />
                                <a href="mailto:support@transweeft.com" style={{ color: "white", textDecoration: 'none' }}>support@transweeft.com</a>
                            </div>

                            {/* Social Media */}
                            <div style={{ textAlign: "left" , paddingBottom: "20px"}}>
                                <strong>Social Media:</strong><br />
                                <div className="d-flex">
                                    <a href="https://web.facebook.com/profile.php?id=61566867814990" target="_blank" rel="noopener noreferrer" className="me-3" style={{ color: "#4267B2" }}>
                                        <FaFacebook size={20} />
                                    </a>
                                    <a href="https://www.linkedin.com/company/105228919/admin/dashboard/" target="_blank" rel="noopener noreferrer" className="me-3" style={{ color: "#0077B5" }}>
                                        <FaLinkedin size={20} />
                                    </a>
                                    <a href="https://x.com/transweeft" target="_blank" rel="noopener noreferrer" className="me-3" style={{ color: "#1DA1F2" }}>
                                        <FaTwitter size={20} />
                                    </a>
                                    <a href="https://www.instagram.com/transweefthq/" target="_blank" rel="noopener noreferrer" style={{ color: "#C13584" }}>
                                        <FaInstagram size={20} />
                                    </a>
                                </div>
                            </div>

                            {/* Download Buttons */}
                                                    <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black",  border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black",  border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>

                            {/* Footer Bottom Links */}
                            <ul style={{ listStyleType: "none", padding: 0, marginTop: "20px", fontWeight: 'bold' }}>
                                <li style={{ marginBottom: "10px" }}>
                                    <Link 
                                        to="/terms" // Use 'to' prop for routing
                                        style={{ color: "white", textDecoration: "none" }}
                                    >
                                        Terms & Condition
                                    </Link>
                                </li>
                                <li style={{ marginBottom: "10px" }}>
                                    <Link
                                        to="/privacy" // Use 'to' prop for routing
                                        style={{ color: "white", textDecoration: "none" }}
                                    >
                                        Privacy
                                    </Link>
                                </li>
                                <li style={{ marginBottom: "10px" }}>
                                 <button 
        style={{ 
            color: "white", 
            textDecoration: "none", 
            background: "none", 
            border: "none", 
            padding: 0, 
            cursor: "pointer",
            fontWeight: "bold",
        }} 
        onClick={() => handleCookiesClick()} // Function to show the Cookies modal
    > 
        Cookies
    </button>
</li>
<li style={{ marginBottom: "10px" }}>
    <button 
        style={{ 
            color: "white", 
            textDecoration: "none", 
            background: "none", 
            border: "none", 
            padding: 0, 
            cursor: "pointer",
            fontWeight: "bold",
        }} 
        onClick={() => handleSecurityClick()} // Function to show the Security modal
    >
        Security
    </button>
</li>

                            </ul>
                        </div>
                    </Col>

                    {/* Get in Touch Form */}
                    <Col xs={12} md={6} className="mb-4 p-4 order-1 order-md-2">
                        <div style={{ backgroundColor: "#F8F8F8", padding: "20px", borderRadius: "8px", color: 'black' }}>
                            <h4 style={{ color: "black", fontWeight: "bold" }}>Get in Touch</h4>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={6} className="mb-3">
                                        <Form.Group controlId="formName">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className="mb-3">
                                        <Form.Group controlId="formSurname">
                                            <Form.Label>Surname</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Surname"
                                                name="surname"
                                                value={formData.surname}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@example.com"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formAddress" className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formDescription" className="mb-3">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        placeholder="Type your message here"
                                    />
                                </Form.Group>
                                <Button type="submit" style={{ backgroundColor: "#67CA1B", color: "black", fontWeight: "bold", border: "none" }}>
                                    Send Message
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
 {/* Copyright Section */}
                <Row className="text-center mt-4">
                    <Col style={{ fontSize: 'small' }}>
                        &copy; {new Date().getFullYear()} Transweeft. All rights reserved.
                    </Col>
                </Row>

            </Container>

            {/* Feedback Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Body style={{ textAlign: 'center', padding: '30px', border: '2px solid #67CA1B' }}>
                    {isSubmitting ? (
                        <Spinner animation="border" style={{ color: "#67CA1B" }} />
                    ) : isSuccess ? (
                        <div>
                            <MdCheckCircle size={40} style={{ color: "#67CA1B" }} />
                            <p style={{ marginTop: '15px', color: "#000000" }}>{modalMessage}</p>
                        </div>
                    ) : (
                        <div>
                            <MdError size={40} style={{ color: "red" }} />
                            <p style={{ marginTop: '15px', color: "#000000" }}>{modalMessage}</p>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

             {/* Cookie Consent Modal */}
            <Modal show={showCookiesModal} onHide={handleCloseCookies} centered style={{ margin: 'auto' }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '14px' }}>Cookie Consent</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '10px', padding: '15px' }}>
                    <p>Transweeft website uses cookies to ensure you get the best experience on our website.</p>
                    <p>
                        <a 
                            href="/privacy" 
                            style={{ 
                                color: "black", 
                                border: '1px solid black', 
                                padding: '3px 8px', 
                                borderRadius: '4px', 
                                textDecoration: 'none' 
                            }}
                        >
                            Learn more
                        </a> 
                        about our privacy policy.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseCookies} style={{ fontSize: '14px' }}>
            Reject
        </Button>
                    <Button variant="dark" onClick={handleCloseCookies} style={{ backgroundColor: "#67CA1B", border: "none" }}>
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Security Modal */}
            <Modal show={showSecurityModal} onHide={handleCloseSecurity} centered style={{ margin: 'auto' }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '14px' }}>Security</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '10px', padding: '15px' }}>
                    <p>Your security is our top priority. Transweeft is committed to protecting your personal information and ensuring its confidentiality.</p>
                    <p>For more details, please refer to our <a href="/privacy" style={{                                 color: "black", 
                                border: '1px solid black', 
                                padding: '3px 8px', 
                                borderRadius: '4px', 
                                textDecoration: 'none'  }}>Privacy Policy</a>.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleCloseSecurity} style={{ backgroundColor: "#67CA1B", border: "none" }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </footer>
    );
};

export default Footer;

