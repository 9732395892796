import React from 'react';
import { Container } from 'react-bootstrap';
import { FaUser, FaCar, FaCreditCard, FaExclamationTriangle, FaGavel } from 'react-icons/fa';

const TermsAndConditions = () => {
    return (
        <Container 
            style={{ 
                padding: '120px 30px', 
                borderRadius: '10px', 
                
                margin: 'auto', // Center the container
            }}>
            <h1 style={{ color: '#67CA1B', fontWeight: 'bold', textAlign: 'center', marginBottom: '30px' }}>
                Terms of Service
            </h1>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                Welcome to Transweeft! By accessing or using our mobile app, you agree to be bound by these Terms of Service. Please read them carefully to understand your rights and responsibilities.
            </p>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaUser /> Definitions
            </h2>
            <ul style={{ textAlign: 'justify', marginBottom: '20px', listStyleType: 'none', paddingLeft: '0' }}>
                <li><strong>Transweeft:</strong> Refers to the platform providing ride-sharing services.</li>
                <li><strong>User:</strong> Means anyone who accesses or uses the Transweeft platform.</li>
                <li><strong>Driver:</strong> Refers to a user who offers rides through Transweeft.</li>
                <li><strong>Rider:</strong> Refers to a user who books a ride through Transweeft.</li>
            </ul>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaUser /> User Eligibility
            </h2>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                Users must be at least <strong>16 years</strong> old to register for an account. By creating an account, users affirm that the information provided is accurate and current.
            </p>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaCar /> Use of the Platform
            </h2>
            <ul style={{ textAlign: 'justify', marginBottom: '20px', listStyleType: 'none', paddingLeft: '0' }}>
                <li><strong>Account Creation:</strong> Users must create an account and provide accurate details to use the service.</li>
                <li><strong>Ride Booking:</strong> Riders can request rides, and drivers can choose to accept or decline based on availability.</li>
                <li><strong>Prohibited Activities:</strong> Users must not use the platform for illegal activities, harassment, or any form of misconduct.</li>
            </ul>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaCreditCard /> Payment and Fees
            </h2>
            <ul style={{ textAlign: 'justify', marginBottom: '20px', listStyleType: 'none', paddingLeft: '0' }}>
                <li><strong>Ride Costs:</strong> Fares are calculated based on factors like distance and demand. Fare estimates are provided before booking.</li>
                <li><strong>Payments:</strong> Riders must use a valid payment method. Payments can be made via in-app payments, mobile money, or other supported methods.</li>
                <li><strong>Refunds and Cancellations:</strong> Users can cancel rides before the driver arrives; however, a fee may be charged if the driver is already en route. Refunds are reviewed on a case-by-case basis.</li>
            </ul>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaExclamationTriangle /> User Responsibilities
            </h2>
            <ul style={{ textAlign: 'justify', marginBottom: '20px', listStyleType: 'none', paddingLeft: '0' }}>
                <li><strong>Riders:</strong> Must be available at the pickup location on time and must respect the driver and their vehicle.</li>
                <li><strong>Drivers:</strong> Must comply with Nigerian traffic regulations, maintain their vehicle in safe condition, and provide quality service.</li>
            </ul>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                <FaGavel /> Limitation of Liability
            </h2>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                Transweeft provides its services “as is” without warranties. We are not liable for damages resulting from the use of the platform.
            </p>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                Dispute Resolution
            </h2>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                Users agree to attempt resolution through Transweeft’s customer support before escalating disputes. If unresolved, disputes will be settled under Nigerian law.
            </p>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                Governing Law
            </h2>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                These Terms of Service are governed by the laws of Nigeria.
            </p>

            <h2 style={{ color: '#67CA1B', fontWeight: 'bold', marginTop: '20px' }}>
                Contact Information
            </h2>
            <p style={{ textAlign: 'justify', marginBottom: '20px' }}>
                For questions or concerns, contact us at <a href="mailto:info@transweeft.com" style={{ color: '#67CA1B', textDecoration: "none" }}>info@transweeft.com</a>.
            </p>
        </Container>
    );
};

export default TermsAndConditions;
