import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        howItWorks: "How it Works",
        aboutUs: "About Us",
        safety: "Safety",
        helpSupport: "Help & Support",
        downloadApp: "Download Our App"
        // Add more translations as needed
      },
    },
    yo: {
      translation: {
        home: "Ile",
        howItWorks: "Bawo ni O Se Nṣiṣẹ",
        aboutUs: "Nipa Wa",
        safety: "Aabo",
        helpSupport: "Iranlọwọ & Atilẹyin",
        downloadApp: "Gba App Wa"
      },
    },
    ig: {
      translation: {
        home: "Ụlọ",
        howItWorks: "Olee ka Ọ Si Na-arụ Ọrụ",
        aboutUs: "Banyere Anyị",
        safety: "Nchekwa",
        helpSupport: "Enyemaka & Nkwado",
        downloadApp: "Budata Ngwa Anyị"
      },
    },
    ha: {
      translation: {
        home: "Gida",
        howItWorks: "Yadda Yake Aiki",
        aboutUs: "Game da Mu",
        safety: "Tsaro",
        helpSupport: "Taimako & Tallafi",
        downloadApp: "Zazzage Aikace-aikacenmu"
      },
    },
  },
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

export default i18n;
