import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';


const FaqItem = ({ eventKey, question, answer }) => {
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    return (
        <Accordion.Item
            eventKey={eventKey}
            onClick={handleToggle}
            style={{
                backgroundColor: isActive ? '#FFFFFF' : '#F8F8F8',
                border: 'none',
            }}
        >
            <Accordion.Header
                style={{
                    backgroundColor: isActive ? '#FFFFFF' : '#F8F8F8',
                    color: '#67CA1B',
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                {question}
            </Accordion.Header>
            <Accordion.Body
                style={{
                    backgroundColor: isActive ? '#FFFFFF' : '#F8F8F8',
                    border: 'none',
                    fontSize: '14px',
                }}
            >
                {answer}
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default FaqItem;
