import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Iphone15 from './assets/images/iPhone15Max.png';
import Driver_core from './assets/images/Driver_core.png';
import Real_core from './assets/images/Real_core.png';
import Panic_core from './assets/images/Panic_core.png';
import Accident_core from './assets/images/Accident_core.png';   
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';
import SignupImage from './assets/images/Signup.png'; 
import Driver_image from './assets/images/Driver_Image.png'; 
import Real_time from './assets/images/select -driver.png'; 

const Safety = () => {
    return (
       <>
            <Container fluid style={{ backgroundColor: 'black', paddingTop: '120px', display: 'flex', alignItems: 'center' }}>
                <Row style={{ width: '100%' }}>
                    {/* Text on the left */}
                    <Col xs={12} md={6} style={{ color: 'white', textAlign: 'left', padding: '40px' }}>
                        <h1 style={{ fontWeight: 'bold' }}>Your Safety,Our Priority</h1>
                        <p className="how-it-works-fade-text" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                        At Transweeft, we’ve built safety into every ride with trusted drivers, real-time tracking, and emergency support.
                        </p>
                        <div style={{ paddingTop: '20px' }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button
                                    className="how-it-works-get-started-button"
                                    variant="outline-primary"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        background: '#87F731',
                                        color: 'black',
                                        border: 'none',
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                >
                                Learn More
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    {/* Taxi Image on the right */}
                    <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img 
                            src={Iphone15} 
                            alt="Transparent Taxi Cab" 
                            style={{
                                maxWidth: '100%', // Ensure the image is responsive
                                objectFit: 'contain', 
                                height: '40vh',
                            }} 
                        />
                    </Col>
                </Row>
            </Container>
            
            {/*Our Core Safety div */}
{/* Feature List Section of Our Core Safety */}
            <div className="feature-list-section mt-4" style={{ padding: '30px' }}>
                <Container>
                    {/* Feature List Heading */}
                    <div
                        className="feature-list-header"
                        style={{ textAlign: 'left', marginBottom: '20px' }}
                    >
                        <h3 style={{ color: '#67CA1B', fontWeight: 'bold', }}>Our Core Safety Features</h3>
                    </div>  

                    {/* Feature Images in what we offer */}
                    <Row className="g-2">
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Driver_core} alt="Feature 1" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Real_core} alt="Feature 2" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Panic_core} alt="Feature 3" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Accident_core} alt="Feature 4" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Container>
            </div>


{/*Verified Driver div */}
<div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px',}}>
  {/* Verified Driver Section */}
  <div className="Verified_driver" style={{ padding: '30px', flex: '1 1 45%' }}>
    <Container>
      <div className="row align-items-center">
        {/* Text Section */}
        <div className="mb-1 mb-md-0">
          <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Verified Drivers</h3>
        </div>
        {/* Image Section */}
        <div>
          <img src={Driver_image} alt="Verified drivers" className="img-fluid rounded" />
        </div>
        <p style={{ color: '#000000', fontSize: 'small' }}>
          Every driver is thoroughly vetted before they can offer rides on Transweeft.
        </p>
      </div>
    </Container>
  </div>

  {/* Select Driver Section */}
  <div className="Select_driver mt-0" style={{ padding: '0 30px 30px 30px', flex: '1 1 45%' }}>
    <Container>
      <div className="row align-items-center">
        {/* Image Section */}
        <div>
          <img src={Real_time} alt="Real-time monitoring" className="img-fluid rounded" style={{ maxWidth: '100%', width: '100%', height: 'auto',}}  />
        </div>
      </div>
      {/* Text Section */}
      <div className="mt-2 mb-md-0">
        <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Real-Time Monitoring</h3>
      </div>
    </Container>
  </div>
</div>

             {/* sign up div */}
           <div className="sign-up-login-section" style={{ padding: '30px' }}>
  <Container>
    <div className="row align-items-center">
      {/* Text Section */}
      <div className="col-md-6">
        <h2 style={{ color: '#67CA1B', fontWeight: 'bold'}}>Sign Up / Login</h2>
        <p style={{ fontSize: 'small'}}>
        Transweeft allows users to sign up or log in to access the app's services. It provides a seamless entry point for users to create accounts or access their existing accounts.
        </p>
         <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>
      </div>

      {/* Image Section */}
      <div className="col-md-6 d-flex justify-content-center mt-5">
        <img
          src={SignupImage}
          alt="Transweeft SignUp"
          style={{
            maxWidth: '1090px', // Limiting the width to the specified dimension
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            justifyContent: 'center',
          }}
        />
      </div>
    </div>
  </Container>
</div>
        </>
    );
};

export default Safety;
