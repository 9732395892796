// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // Ensure you have the correct path to your App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Your i18n configuration

// Create a root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app with the I18nextProvider
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);
