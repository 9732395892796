/* global Tawk_API */
import React, { useEffect } from 'react';
import { Container, Row, Col, Accordion, Button ,Card } from 'react-bootstrap';
import FaqItem from './FaqItem'; 
import { Link } from 'react-router-dom';
import SupportWrapper from './assets/images/Support.png';
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';
import SignupImage from './assets/images/Signup.png';  
import Help_image from './assets/images/Help.png';
import Email_image from './assets/images/Email.png';
import Call_image from './assets/images/Call.png';

const HelpSupport = () => {
    useEffect(() => {
        // Load Tawk.to script only if it's not already loaded
        if (typeof Tawk_API === 'undefined') {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://embed.tawk.to/6705c6c8cec6d0125df37f9d/1i9n910o4';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');
            document.body.appendChild(script);

            // Ensure chat doesn't automatically open on page load
            script.onload = () => {
                if (typeof Tawk_API !== 'undefined') {
                    Tawk_API.onLoad = function () {
                        Tawk_API.minimize(); // Make sure the widget starts minimized
                    };
                }
            };
        } else {
            // If Tawk_API is already loaded, just show the widget
            Tawk_API.showWidget();
        }

        // Show the widget when on Help and Support page
        if (typeof Tawk_API !== 'undefined') {
            Tawk_API.showWidget(); // Show the widget every time the component mounts
        }

        // Cleanup function to hide the widget when navigating away (optional)
        return () => {
            if (typeof Tawk_API !== 'undefined') {
                Tawk_API.hideWidget(); // This can be removed if you want it to stay hidden
            }
        };
    }, []); 




    return (
        
        <Container fluid >
                    <Row className="align-items-center">
                    {/* Text Wrapper with black background */}
                    <Col md={6} className="how-it-works-text-wrapper" style={{ height: '89vh', paddingTop: '120px', backgroundColor: '#191919', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div className="how-it-works-text-wrap" style={{ textAlign: 'left', zIndex: 2, padding:'30px' }}>
                            <h1 className="how-it-works-hero-title" style={{ color: 'white', fontWeight: 'bold',}}>
                             Find your Ride, Build Connections
                            </h1>
                            <p className="how-it-works-fade-text" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                            Affordable, convenient rides that bring people together.
                            </p>
                            <div style={{ paddingTop: '20px' }}>
                                <Link to="/" style={{ textDecoration: 'none' }}> {/* Use Link for navigation */}
                                    <Button
                                        className="how-it-works-get-started-button"
                                        variant="outline-primary"
                                        style={{
                                            padding: '10px 20px',
                                            fontSize: '16px',
                                            background: '#87F731',
                                            color: 'black',
                                            border: 'none',
                                            transition: 'all 0.3s ease-in-out'
                                        }}
                                    >
                                        Get Started
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        {/* Mobile background image for text wrapper */}
                        <div style={{
                            backgroundImage: `url(${SupportWrapper})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            opacity: 0.3, // Optional: Add some opacity for better text readability
                        }} className="d-md-none" /> {/* Ensure this div only appears on mobile */}
                    </Col>
                    {/* Image Wrapper for desktop */}
                    <Col md={6} className="how-it-works-image-wrapper d-none d-md-block" style={{
                        backgroundImage: `url(${SupportWrapper})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '89vh',
                        margin: 0
                    }}>
                        {/* Additional content can go here if needed */}
                    </Col>
                </Row>
                


                {/* NeedsHelp div */}
            <div className="testimonial-section" style={{ padding: '30px'}}>
                <Container>
                    {/* Testimonial Header */}
                    <div className="testimonial-header" style={{ textAlign: 'center', marginBottom: '30px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
  <h3 style={{ color: '#67CA1B', fontWeight: 'bold', whiteSpace: 'nowrap', margin: 0 }}>
    Need Help? We’re Here for you
  </h3>
</div>
        <p style={{ color: '#000000', fontSize: 'small' }}>
                    Explore our resources to get in touch with our support team for assistance
                        </p>
                    </div>

                    {/* Testimonial Cards */}
                    <Row className="justify-content-center g-4">
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', 
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: '155px', 
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={Help_image}
      alt="driver1"
      style={{
        border: 'none',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>General Help</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    Find answers to common questions and get quick solutions to common issues.
    </Card.Text>
  </div>
</Card>

                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', // Align items to the top
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={Email_image}
      alt="driver2"
      style={{
        border: 'none',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
  <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem', paddingTop: '10px' }}>Email Support</Card.Title>
  <Card.Text style={{ fontSize: '0.8rem', margin: 0, lineHeight: '1.4' }}>
    Send us an email at <span style={{ whiteSpace: 'nowrap' }}>
      <a href="mailto:support@transweeft.com" style={{ textDecoration: 'none', color: 'inherit' }}>
        support@transweeft.com
      </a>
    </span> and our support team will get back to you promptly.
  </Card.Text>
</div>
</Card>

                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', // Align items to the top
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
     height: '155px',
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={Call_image}
      alt="driver3"
      style={{
       border: 'none',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Call Us</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
Our 24/7 support hotline is here to assist you with urgent matters.
    </Card.Text>
  </div>
</Card>
                        </Col>
                    </Row> 
                </Container>
            </div>












            {/* FAQ Section */}
            <div className='Faq-section' style={{ backgroundColor: '#F8F8F8', padding: '10px',margin: '20px', border: '1px solid #E0E0E0', borderRadius: '10px' }}>
    <h3 style={{ textAlign: 'center', color: '#67CA1B', marginTop: '60px', fontWeight: 'bold' }}>Frequently Asked Questions</h3>
                <Row className="justify-content-center p-3">
                <Col md={8} className="mb-5">
                    <Accordion className="faq-accordion">
                        <FaqItem 
                            eventKey="0" 
                            question="How do I book a ride on Transweeft?" 
                            answer="To book a ride, download the Transweeft app from the Apple App Store or Google Play Store. Sign up or log in, enter your pickup and drop-off locations, and request a ride. You’ll be matched with a nearby driver who can share the journey with you."
                        />
                        <FaqItem 
                            eventKey="1" 
                            question="Is Transweeft available in my city?" 
                            answer="Currently, Transweeft is only available in Lagos State. We are working on expanding to other locations across Nigeria, so stay tuned for updates on our coverage areas."
                        />
                        <FaqItem 
                            eventKey="2" 
                            question="Can I schedule a ride in advance?" 
                            answer="Currently, Transweeft does not offer ride scheduling in advance. All rides are requested on-demand, so you can book a ride whenever you need it, and a nearby driver will be matched with you in real time."
                        />
                        <FaqItem 
                            eventKey="3" 
                            question="How can I become a verified driver on Transweeft?" 
                            answer="To become a verified driver, sign up on the Transweeft app and complete the registration process, which includes submitting your driver’s license, vehicle documents, and other required details. Once your documents are reviewed and you pass a background check, you will receive verification and can start accepting ride requests."
                        />
                        <FaqItem 
                            eventKey="4" 
                            question="Are there any restrictions on the type of vehicles to be used?" 
                            answer="Yes, vehicles used for Transweeft must meet certain requirements, including being in good working condition, having four doors, and passing a safety inspection. Specific vehicle model restrictions may also apply based on our standards for passenger comfort and safety."
                        />
                        <FaqItem 
                            eventKey="5" 
                            question="Can I share a ride with multiple passengers?" 
                            answer="Yes, Transweeft allows you to share a ride with multiple passengers heading in the same direction. The fare will be split among all riders, making it more cost-effective for everyone."
                        />
                        <FaqItem 
                            eventKey="6" 
                            question="How can I contact support if I have an issue with my ride?" 
                            answer="You can reach our customer support team via email at support@transweeft.com, through the app’s help center, or by calling our support hotline for urgent issues. We are available 24/7 to assist with any queries or concerns."
                        />
                        <FaqItem 
                            eventKey="7" 
                            question="How does the cost-sharing work?" 
                            answer="The cost of each ride is shared among the passengers based on distance and demand. You will see a fare estimate before confirming your ride, and the final fare will be split proportionally with other riders in the vehicle."
                        />
                        <FaqItem 
                            eventKey="8" 
                            question="What happens if I cancel my ride?" 
                            answer="If you cancel a ride before the driver arrives, you may not incur a cancellation fee. However, if the driver is already on their way, a small fee may be charged to compensate for their time and effort."
                        />
                        <FaqItem 
                            eventKey="9" 
                            question="Is my personal information secure on the platform?" 
                            answer="Yes, we take data privacy seriously and implement security measures to protect your personal information. Your data is used only for providing the ride-sharing service, as outlined in our Privacy Policy."
                        />
                    </Accordion>
                </Col>
            </Row>
            </div>

             {/* sign up div */}
           <div className="sign-up-login-section" style={{ padding: '30px' }}>
  <Container>
    <div className="row align-items-center">
      {/* Text Section */}
      <div className="col-md-6">
        <h2 style={{ color: '#67CA1B', fontWeight: 'bold'}}>Sign Up / Login</h2>
        <p style={{ fontSize: 'small'}}>
        Transweeft allows users to sign up or log in to access the app's services. It provides a seamless entry point for users to create accounts or access their existing accounts.
        </p>
         <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>
      </div>

      {/* Image Section */}
      <div className="col-md-6 d-flex justify-content-center mt-5">
        <img
          src={SignupImage}
          alt="Transweeft SignUp"
          style={{
            maxWidth: '1090px', // Limiting the width to the specified dimension
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            justifyContent: 'center',
          }}
        />
      </div>
    </div>
  </Container>
</div>
        </Container>
        
    );
};

export default HelpSupport;
