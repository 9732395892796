import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaShieldAlt, FaRegUser, FaRegClock } from 'react-icons/fa'; // Bootstrap Icons

const PrivacyPolicy = () => {
    return (
        <Container style={{ padding: '120px 30px'}}>
            <h1 style={{ textAlign: 'center', color: '#67CA1B', fontWeight: 'bold' }}>Privacy Policy</h1>
            <p style={{ textAlign: 'center', fontSize: 'small', marginBottom: '30px' }}>
               
            </p>

            <Row>
                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaShieldAlt style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>Introduction</strong>
                    <p>
                        Welcome to Transweeft! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our services.
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaRegUser style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px', }} />
                    <strong>Information We Collect</strong>
                    <p>
                        We collect the following types of information:
                        <ul>
                            <li>Personal Information: Name, email, phone number, etc.</li>
                            <li>Location Data: To provide ride-sharing services.</li>
                            <li>Usage Data: Information about how you use our app.</li>
                        </ul>
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaRegClock style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>How We Use Your Information</strong>
                    <p>
                        We use the information we collect to:
                        <ul>
                            <li>Provide and maintain our services.</li>
                            <li>Improve our app and user experience.</li>
                            <li>Communicate with you about your account or services.</li>
                        </ul>
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaShieldAlt style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>Data Protection</strong>
                    <p>
                        We implement various security measures to ensure the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure.
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaRegUser style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>Your Rights</strong>
                    <p>
                        You have the right to access, correct, or delete your personal information. You can also opt-out of receiving promotional communications from us.
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaRegClock style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>Changes to This Policy</strong>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                </Col>

                <Col md={12} style={{ marginBottom: '20px' }}>
                    <FaShieldAlt style={{ fontSize: '24px', color: '#67CA1B', marginRight: '10px' }} />
                    <strong>Contact Us</strong>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@transweeft.com" style={{ color: '#67CA1B', textDecoration: "none" }}>info@transweeft.com</a>.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicy;
