import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../App.css'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from './assets/images/Logo.png';
import logo2 from './assets/images/Logo2.png'; 

const Header = () => {
  const { t,} = useTranslation();
  const [scrolling, setScrolling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLinkClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      expanded={isExpanded}
      className="nav-header"
      style={{
        backgroundColor: (isMobile && !scrolling && !isExpanded) ? 'transparent' : '#67CA1B',
      }}
    >
      <Container>
        <Link to="/" className="navbar-brand me-auto">
          <img src={isMobile ? logo2 : logo} alt="Logo" className="logo" />
        </Link>
        
        {/* Language Dropdown Removed */}
        
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ border: 'none', outline: 'none', boxShadow: 'none' }}
        >
          {isExpanded ? <i className="bi bi-x" style={{ color: '#FFFFFF' }} /> : <i className="bi bi-list" style={{ color: '#FFFFFF' }} />}
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Link to="/" className="nav-link" onClick={handleLinkClick}>{t('home')}</Link>
            <Link to="/how-it-works" className="nav-link" onClick={handleLinkClick}>{t('howItWorks')}</Link>
            <Link to="/about-us" className="nav-link" onClick={handleLinkClick}>{t('aboutUs')}</Link>
            <Link to="/safety" className="nav-link" onClick={handleLinkClick}>{t('safety')}</Link>
            <Link to="/help-support" className="nav-link" onClick={handleLinkClick}>{t('helpSupport')}</Link>
          </Nav>
          
          <Button 
            variant="outline-success" 
            className="me-2"
            style={{ borderColor: '#67CA1B', color: '#137639', backgroundColor: 'transparent', fontWeight: 'bold' }}
            onClick={() => window.open('https://link-to-playstore.com', '_blank')}
          >
            {t('Download App')}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
