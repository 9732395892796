import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HiwWrapper from './assets/images/hiw_wrapper.png'; 
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';
import SignupImage from './assets/images/Signup.png';  
import EcoDriveImage from './assets/images/Eco_drive.png';
import FamilyFleetImage from './assets/images/Familyfleet.png';
import SweefRideImage from './assets/images/Sweef_ride.png';
import pickupImage from './assets/images/Pickup_Image.png'; 
import stickerImage from './assets/images/sticker.png'; 
import pickupDropImage from './assets/images/Pickup_drop.png'; 
import popoverImage from './assets/images/popover.png';
import preferredDriverImage from './assets/images/Preferred_driver.png';
import chatImage from './assets/images/Chat_image.png';
import chatWithDriver from './assets/images/Chat_with_driver.png';
import chatWithRider from './assets/images/Chat_with_rider.png';


const HowItWorksSection = () => {
    return (
            <Container fluid>
                <Row className="align-items-center">
                    {/* Text Wrapper with black background */}
                    <Col md={6} className="how-it-works-text-wrapper" style={{ height: '94vh', paddingTop: '120px', backgroundColor: '#191919', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div className="how-it-works-text-wrap" style={{ textAlign: 'left', zIndex: 2, padding:'30px' }}>
                            <h1 className="how-it-works-hero-title" style={{ color: 'white', fontWeight: 'bold',}}>
                                Welcome to the Future of Transportation
                            </h1>
                            <p className="how-it-works-fade-text" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                                Experience seamless rides at your fingertips.
                            </p>
                            <div style={{ paddingTop: '20px' }}>
                                <Link to="/" style={{ textDecoration: 'none' }}> {/* Use Link for navigation */}
                                    <Button
                                        className="how-it-works-get-started-button"
                                        variant="outline-primary"
                                        style={{
                                            padding: '10px 20px',
                                            fontSize: '16px',
                                            background: '#87F731',
                                            color: 'black',
                                            border: 'none',
                                            transition: 'all 0.3s ease-in-out'
                                        }}
                                    >
                                        Get Started
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        {/* Mobile background image for text wrapper */}
                        <div style={{
                            backgroundImage: `url(${HiwWrapper})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            opacity: 0.3, // Optional: Add some opacity for better text readability
                        }} className="d-md-none" /> {/* Ensure this div only appears on mobile */}
                    </Col>
                    {/* Image Wrapper for desktop */}
                    <Col md={6} className="how-it-works-image-wrapper d-none d-md-block" style={{
                        backgroundImage: `url(${HiwWrapper})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '94vh',
                        margin: 0
                    }}>
                        {/* Additional content can go here if needed */}
                    </Col>
                </Row>
            
            {/* choose your ride */}
<div className="container" style={{ padding: '30px' }}>
      {/* Main Title */}
      <h2 className="font-weight-bold" style={{ color: '#67CA1B', fontWeight: 'bold' }}>
        Choose Your Ride
      </h2>

      {/* Description */}
      <p className="small mb-5" style={{ textAlign: 'left' }}>
        Explore the variety of vehicles available for booking on our ride-hailing app.
      </p>
      {/* Cards Row: Display flex on larger screens */}
<div className="row row-cols-1 row-cols-md-3 g-4">
  {/* Card 1: Sweeftride */}
  <div className="col d-flex">
    <div className="card" style={{ backgroundColor: '#F8F8F8', flex: 1, border: 'none' }}>
      <div className="card-body text-left">
        <h3 className="card-title font-weight-bold" style={{ fontWeight: 'bold' }}>Sweeftride</h3>
        <img
          src={SweefRideImage}
          alt="Sweef Ride"
          className="card-img-top mb-3 animate-left"
          style={{
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <p className="card-text small" style={{ textAlign: 'left' }}>
          Arrive in style and comfort with our selection of luxury cars.
        </p>
      </div>
    </div>
  </div>

  {/* Card 2: FamilyFleet */}
  <div className="col d-flex">
    <div className="card" style={{ backgroundColor: '#F8F8F8', flex: 1, border: 'none' }}>
      <div className="card-body text-left">
        <h3 className="card-title font-weight-bold" style={{ fontWeight: 'bold' }}>FamilyFleet</h3>
        <img
          src={FamilyFleetImage}
          alt="Family Fleet"
          className="card-img-top mb-3 animate-right"
          style={{
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <p className="card-text small" style={{ textAlign: 'left' }}>
          Navigate through traffic effortlessly with our convenient commercial options.
        </p>
      </div>
    </div>
  </div>

  {/* Card 3: Eco Drive */}
  <div className="col d-flex">
    <div className="card" style={{ backgroundColor: '#F8F8F8', flex: 1, border: 'none' }}>
      <div className="card-body text-left">
        <h3 className="card-title font-weight-bold" style={{ fontWeight: 'bold' }}>Eco Drive</h3>
        <img
          src={EcoDriveImage}
          alt="Eco Drive"
          className="card-img-top mb-3 animate-left"
          style={{
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <p className="card-text small" style={{ textAlign: 'left' }}>
          Go green and zip through the city on our eco-friendly electric vehicles.
        </p>
      </div>
    </div>
  </div>
</div>

    </div>
              {/* sign up div */}
           <div className="sign-up-login-section" style={{ padding: '30px' }}>
  <Container>
    <div className="row align-items-center">
      {/* Text Section */}
      <div className="col-md-6">
        <h2 style={{ color: '#67CA1B', fontWeight: 'bold'}}>Sign Up / Login</h2>
        <p style={{ fontSize: 'small'}}>
        Transweeft allows users to sign up or log in to access the app's services. It provides a seamless entry point for users to create accounts or access their existing accounts.
        </p>
         <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>
      </div>

      {/* Image Section */}
      <div className="col-md-6 d-flex justify-content-center mt-5">
        <img
          src={SignupImage}
          alt="Transweeft SignUp"
          style={{
            maxWidth: '1090px', // Limiting the width to the specified dimension
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            justifyContent: 'center',
          }}
        />
      </div>
    </div>
  </Container>
</div>

{/* Pick-up Image with Sticker */}
<div className="container" style={{ padding: '30px' }}>
  {/* Pick-up Image with Sticker */}
  <div className="d-flex flex-column flex-lg-row align-items-center mb-4">    
    <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
  <div className="position-relative w-100 w-lg-50 mb-3 mb-lg-0">
    <img src={pickupImage} alt="Pickup" className="img-fluid" />
    <img
      src={stickerImage}
      alt="Sticker"
      className="position-absolute top-0 end-0"
      style={{
        width: '50px',
        height: '50px',
        marginTop: '-15px',
        marginRight: '-15px',
        animation: 'rotateSticker 2s linear infinite', // Apply rotation animation
      }}
    />
  </div>
</div>


    {/* Select Pick-up and Drop-off Location */}
    <div className="w-100 w-lg-50 ps-0 ps-lg-4">
      <h2 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Select Pick-up and Drop-off Location</h2>
      <img src={pickupDropImage} alt="Pick-up and Drop-off" className="img-fluid" />

      {/* Always horizontally aligned flex divs */}
      <div className="d-flex justify-content-between gap-4">
        {/* First Flex Div */}
        <div className="w-48">
          <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Customize your own rider</h5>
          <p style={{ fontSize: 'small' }}>Choose how you ride to your destination.</p>
        </div>

        {/* Second Flex Div */}
        <div className="w-48">
          <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Desired Destination</h5>
          <p style={{ fontSize: 'small' }}>Input your current and desired destination.</p>
        </div>
      </div>
    </div>
  </div>
</div>

 {/* choose driver div */}
    <div className="container" style={{ padding: '30px' }}>
  <div className="d-flex flex-column flex-lg-row align-items-center">
    {/* Text Div on the Left */}
    <div className="w-100 w-lg-50 text-start pe-lg-4 mb-lg-0">
      <h2 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Choose Preferred Driver to your Location</h2>
      <img src={preferredDriverImage} alt="Preferred Driver" className="img-fluid" />

      {/* Always horizontally aligned flex divs */}
      <div className="d-flex justify-content-between gap-4">
        {/* First Flex Div */}
        <div className="w-48">
          <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Select Drivers of Choice</h5>
          <p style={{ fontSize: 'small' }}>Preview your ride availability of Status, Seats, and Pricing.</p>
        </div>

        {/* Second Flex Div */}
        <div className="w-48">
          <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Preview Driver Information</h5>
          <p style={{ fontSize: 'small' }}>Check Driver history of Trips, Routes, and Ratings.</p>
        </div>
      </div>
    </div>

    {/* Image Div on the Right */}
    <div className="w-100 w-lg-50 text-center">
      <img src={popoverImage} alt="Popover" className="img-fluid" />
    </div>
  </div>
</div>
             {/* chat connect div */}
            <div className="container mt-5">
      {/* Chat and Connect Header */}
      <div className="d-flex flex-column flex-lg-row align-items-center">
         {/* Right Section: iPhone Images Side-by-Side */}
        <div className="w-100 w-lg-50 d-flex justify-content-center gap-3 mt-4 mt-lg-0 mb-5">
          <img src={chatWithDriver} alt="Chat with Driver" className="img-fluid" style={{ width: '150px', height: 'auto' }} />
          <img src={chatWithRider} alt="Chat with Rider" className="img-fluid" style={{ width: '150px', height: 'auto' }} />
        </div>
        {/* Left Section: Chat Header and Image */}
        <div className="w-100 w-lg-50 mb-4 mb-lg-0 text-start">
          <h2 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Chat and Connect with your Ride on-the-Go!</h2>
          <img src={chatImage} alt="Chat and Connect" className="img-fluid mb-3" />

          {/* Two Text Sections in Horizontal Flexbox */}
          <div className="d-flex justify-content-between gap-4">
            {/* First Text Div */}
            <div className="w-48">
              <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Communities brings Members together</h5>
              <p style={{ fontSize: 'small' }}>Members can receive updates and announcements, they can also create/organize smaller groups on what matters to them.</p>
            </div>

            {/* Second Text Div */}
            <div className="w-48">
              <h5 style={{ fontWeight: 'bold', fontSize: 'small' }}>Virtual Support</h5>
              <p style={{ fontSize: 'small' }}>Quick access to all your basic needs in-app without hassle.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
            </Container>
    );
};

export default HowItWorksSection;
