import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import TransCab from './assets/images/Trans_cab.png';
import carpooling from './assets/images/carpooling.png'; 
import our_vision from './assets/images/our_vision.png'; 
import Affordable_ride from './assets/images/Affordable.png';
import Safety_ride from './assets/images/Safety.png';
import Relax_ride from './assets/images/Convinence.png';
import Enviroment_safe from './assets/images/Enviroment.png';
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';
import SignupImage from './assets/images/Signup.png'; 
const AboutUs = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: 'black', paddingTop: '120px', display: 'flex', alignItems: 'center' }}>
                <Row style={{ width: '100%' }}>
                    {/* Text on the left */}
                    <Col xs={12} md={6} style={{ color: 'white', textAlign: 'left', padding: '30px' }}>
                        <h1 style={{ fontWeight: 'bold' }}>Building a Greener, Safer Commute</h1>
                        <p className="how-it-works-fade-text" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                            We make transportation more affordable, safer, and better for the environment by connecting everyday commuters and car owners.
                        </p>
                        <div style={{ paddingTop: '20px' }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button
                                    className="how-it-works-get-started-button"
                                    variant="outline-primary"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        background: '#87F731',
                                        color: 'black',
                                        border: 'none',
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                >
                                    Get Started
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    {/* Taxi Image on the right */}
{/* Taxi Image on the right with Jump and Smoke Effects */}
<Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div className="taxi-smoke">
    <img 
      src={TransCab} 
      alt="Transparent Taxi Cab" 
      className="taxi-image" 
      style={{
        width: '100%', // Ensure it scales well on smaller devices
        height: 'auto',
        objectFit: 'contain', // Keep aspect ratio intact
      }} 
    />
  </div>
</Col>
                </Row>
            </Container>
         {/* Who we are div */}
<div className="Who we are"style={{ padding: '30px' }}>
    <Container>
      <div className="row align-items-center">
        {/* Text Section */}
        <div className="col-md-6 mb-4 mb-md-0">
          <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Who We Are</h3>
          <p style={{ color: '#000000', fontSize: 'small' }}>
        We are on a mission to make commuting more affordable, convenient, and safe for everyone, while reducing traffic congestion and environmental impact in cities like Lagos and beyond. Our platform connects everyday car owners with commuters heading in the same direction, making travel more efficient and eco-friendly.
            </p>
        </div>
{/* Image Section */}
        <div className="col-md-6">
          <img src={carpooling} alt="Carpooling" className="img-fluid rounded" />
        </div>
      </div>
      </Container>
    </div>
    
     {/* Our Vision div */}
<div className="Our Vision" style={{ padding: '30px' }}>
  <Container>
    <div className="row align-items-center">
      {/* Text Section */}
      <div className="col-md-6 order-md-2 mb-4 mb-md-0">
        <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Our Vision</h3>
        <p style={{ color: '#000000', fontSize: 'small' }}>
          At Transweeft, we believe in the power of community. Our vision is to build a trusted network where people can share rides, cut costs, and contribute to a greener, less congested future. Whether you’re a car owner looking to maximize the use of your vehicle or a commuter searching for a safe and budget-friendly ride, Transweeft offers the perfect solution.
        </p>
      </div>

      {/* Image Section */}
      <div className="col-md-6 order-md-1">
        <img src={our_vision} alt="Our_vision" className="img-fluid rounded" />
      </div>
    </div>
  </Container>
</div>

{/* Feature List Section of what we offer */}
            <div className="feature-list-section" style={{ padding: '30px' }}>
                <Container>
                    {/* Feature List Heading */}
                    <div
                        className="feature-list-header"
                        style={{ textAlign: 'left', marginBottom: '40px' }}
                    >
                        <h3 style={{ color: '#67CA1B', fontWeight: 'bold', }}>What We Offer</h3>
                        <p
                            style={{ color: '#000000', fontSize: 'small' }}
                        >
                        Affordable. Safe. Convenient. Together, for a Greener Future.
                        </p>
                    </div>  

                    {/* Feature Images in what we offer */}
                    <Row className="g-2">
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Affordable_ride} alt="Feature 1" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Safety_ride} alt="Feature 2" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Relax_ride} alt="Feature 3" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }}>
                            <img src={Enviroment_safe} alt="Feature 4" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Container>
            </div>
 {/* sign up div */}
<div className="sign-up-login-section" style={{ padding: '30px' }}>
  <Container>
    <div className="row align-items-center">
      {/* Text Section */}
      <div className="col-md-6">
        <h2 style={{ color: '#67CA1B', fontWeight: 'bold'}}>Sign Up / Login</h2>
        <p style={{ fontSize: 'small'}}>
        Transweeft allows users to sign up or log in to access the app's services. It provides a seamless entry point for users to create accounts or access their existing accounts.
        </p>
         <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>
      </div>

      {/* Image Section */}
      <div className="col-md-6 d-flex justify-content-center mt-5">
        <img
          src={SignupImage}
          alt="Transweeft SignUp"
          style={{
            maxWidth: '1090px', // Limiting the width to the specified dimension
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            justifyContent: 'center',
          }}
        />
      </div>
    </div>
  </Container>
</div>
        </>
    );
};

export default AboutUs;
