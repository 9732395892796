import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { MdCheckCircle, MdError } from 'react-icons/md'; 

const UserInterestModal = ({ handleClose }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        interest: '',
        contactMethod: 'email',
        comments: '',
    });
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const hasShownModal = localStorage.getItem('hasSeenUserInterestModal');

        // Only show the modal if it hasn't been shown before
        if (!hasShownModal) {
            // Wait for 7 seconds before showing the modal
            const timer = setTimeout(() => {
                setShowModal(true);
                localStorage.setItem('hasSeenUserInterestModal', 'true'); // Set the flag in local storage
            }, 7000);

            // Cleanup the timer if the component unmounts before the time is up
            return () => clearTimeout(timer);
        }
    }, []);

    const handleChange = (event, name) => {
        setFormData((prevData) => ({ ...prevData, [name]: event.target.value }));
    };

    const handleSelectChange = (selectedOption, name) => {
        setFormData((prevData) => ({ ...prevData, [name]: selectedOption ? selectedOption.value : '' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        console.log("Submitting form with data: ", formData); // Log form data

        try {
       const response = await fetch('https://transweeft-website-backend.onrender.com/user-interest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            console.log("Server response: ", response); // Log the response object

            if (response.ok) {
                setIsSuccess(true);
                setModalMessage('Thank you! Your information has been submitted successfully.');
            } else {
                setIsSuccess(false);
                setModalMessage('There was an error submitting your information. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsSuccess(false);
            setModalMessage('There was an error submitting your information. Please try again.');
        } finally {
            setSubmitting(false);
            setSubmitted(true);
            setTimeout(() => {
                handleClose();
                setShowModal(false);
            }, 3000);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        handleClose();
    };

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Be the First to Know When We Launch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!submitted ? (
                        <Form onSubmit={handleSubmit}>
                            <div className='d d-flex gap-4'>
                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={(e) => handleChange(e, 'firstName')}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={(e) => handleChange(e, 'lastName')}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handleChange(e, 'email')}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={(e) => handleChange(e, 'phone')}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formInterest">
                                <Form.Label>What is your interest?</Form.Label>
                                <Select
                                    options={[
                                        { value: 'Carpooling', label: 'Carpooling' },
                                        { value: 'Ride Sharing', label: 'Ride Sharing' },
                                        { value: 'Delivery Services', label: 'Delivery Services' },
                                    ]}
                                    onChange={(option) => handleSelectChange(option, 'interest')}
                                    isClearable
                                />
                            </Form.Group>

                            <Form.Group controlId="formContactMethod">
                                <Form.Label>Preferred Method of Contact</Form.Label>
                                <Select
                                    options={[
                                        { value: 'email', label: 'Email' },
                                        { value: 'phone', label: 'Phone' },
                                    ]}
                                    onChange={(option) => handleSelectChange(option, 'contactMethod')}
                                    isClearable
                                />
                            </Form.Group>

                            <Form.Group controlId="formComments">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Any additional comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={(e) => handleChange(e, 'comments')}
                                />
                            </Form.Group>

                            <Button style={{ marginTop: '20px', backgroundColor: '#67CA1B', border: "none" }} type="submit" disabled={submitting}>
                                {submitting ? <Spinner as="span" animation="border" size="sm" /> : 'Submit'}
                            </Button>
                        </Form>
                    ) : (
                        <div className={`text-center ${isSuccess ? 'text-success' : 'text-danger'}`}>
                            {isSuccess ? <MdCheckCircle size={40} /> : <MdError size={40} />}
                            <h5>{modalMessage}</h5>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserInterestModal;
