import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import MaintenanceBanner from './MaintenanceBanner';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import HowItWorks from './components/HowItWorks';
import AboutUs from './components/About';
import Safety from './components/Safety';
import HelpSupport from './components/HelpSupport';
import Privacy from './components/PageLinks/Privacy'; 
import Terms from './components/PageLinks/Terms'; 
import { io } from 'socket.io-client';
import './i18n'; // translator
import ScrollToTop from './components/ScrollToTop'; 



const socket = io('http://localhost:3001'); 

function App() {
    const [isMaintenance] = useState(false); // Set to true to display the maintenance banner

    useEffect(() => {
        socket.on('reload', () => {
            window.location.reload(); // Reload the page when notified
        });

        return () => {
            socket.off('reload'); // Clean up the listener
        };
    }, []);

    return (
        <>
            <BrowserRouter>
                <ScrollToTop /> 
                <MaintenanceBanner isVisible={isMaintenance} />
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/safety" element={<Safety />} />
                    <Route path="/help-support" element={<HelpSupport />} />
                    <Route path="/privacy" element={<Privacy />} /> 
                    <Route path="/terms" element={<Terms />} /> 
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
