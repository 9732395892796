import React, { useEffect, useRef, useState,useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button , Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import mapWrapperImage from './assets/images/map_wrapper.png'; 
import feature1 from './assets/images/feature_list1.png';
import feature2 from './assets/images/feature_list2.png';
import feature3 from './assets/images/feature_list3.png';
import feature4 from './assets/images/feature_list4.png';
import appstoreIcon from './assets/images/appstore_icon.png';
import playstoreIcon from './assets/images/playstore_icon.png';
import user2 from './assets/images/user2.png'; 
import user3 from './assets/images/user3.png'; 
import user4 from './assets/images/user4.png'; 
import keyFeature1 from './assets/images/keyFeature1.png';
import keyFeature2 from './assets/images/keyFeature2.png';
import keyFeature3 from './assets/images/keyFeature3.png';
import driver1 from './assets/images/driver1.png';
import driver2 from './assets/images/driver2.png';
import driver3 from './assets/images/driver3.png';
import UserInterestModal from './UserInterestModal'; 




const Home = ({ downloadRef }) => {
    const headerRef = useRef(null);
    const paragraphRef = useRef(null);
    const imagesRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null)]); // Use useRef to keep stable references
    const [showModal, setShowModal] = useState(false); // State for modal visibility

    // Scroll animation handler
   const handleScroll = useCallback(() => {
    if (headerRef.current) { // Check if the ref is not null
        const headerPosition = headerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Fade in the header and paragraph
        if (headerPosition < windowHeight * 0.8) {
            headerRef.current.classList.add('fade-in');
            if (paragraphRef.current) { // Check for paragraphRef as well
                paragraphRef.current.classList.add('fade-in');
            }
        }

        // Fade in images one by one
        imagesRefs.current.forEach((imgRef, index) => {
            if (imgRef.current) { // Check if imgRef is not null
                const imgPosition = imgRef.current.getBoundingClientRect().top;
                if (imgPosition < windowHeight * 0.8) {
                    imgRef.current.classList.add('fade-in');
                    imgRef.current.style.animationDelay = `${index * 0.2}s`; // Staggered delay
                }
            }
        });
    }
}, []);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        const timer = setTimeout(() => {
            setShowModal(true); // Show modal after 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timer); // Clear the timer on cleanup
        };
    }, [handleScroll]); // Include handleScroll as a dependency

    const handleCloseModal = () => setShowModal(false); // Function to close the modal

    return (
        <div className="home">
            {/* Hero Section */}
            <div className="home-hero-section" style={{ overflow: 'hidden' }}>
            <Container fluid>
                <Row className="align-items-center">
                    {/* Text Wrapper with black background */}
                    <Col md={6} className="how-it-works-text-wrapper" style={{ height: '75vh', paddingTop: '120px', backgroundColor: '#191919', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div className="how-it-works-text-wrap" style={{ textAlign: 'left', zIndex: 2, padding:'30px' }}>
                            <h1 className="how-it-works-hero-title" style={{ color: 'white', fontWeight: 'bold',}}>
                            Redefining Urban Transportation in Nigeria
                            </h1>
                            <p className="how-it-works-fade-text pt-3" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                            Transforming your commute with efficient, sustainable, and community-driven rides. Join us today for a smarter way to travel!
                            </p>
                            <p className="pt-5" style={{ color: '#FFFFFF', fontSize: 'small', opacity: 0.7 }}>
                            Get the app from the app store or play store to start your journey.
                            </p>
                           <div className="button-wrap" style={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
                                <Button className="store-button" variant="outline-primary" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-appstore.com', '_blank')}>
                                    <img src={appstoreIcon} alt="App Store" style={{ width: '20px', marginRight: '5px' }} />
                                    App Store
                                </Button>
                                <Button className="store-button" variant="outline-success" style={{ padding: '5px 10px', fontSize: '12px', background: "#87F731", color: "black", border: "none", transition: "all 0.3s ease-in-out" }} onClick={() => window.open('https://link-to-playstore.com', '_blank')}>
                                    <img src={playstoreIcon} alt="Play Store" style={{ width: '20px', marginRight: '5px' }} />
                                    Play Store
                                </Button>
                            </div>
                        </div>
                        {/* Mobile background image for text wrapper */}
                        <div style={{
                            backgroundImage: `url(${mapWrapperImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            opacity: 0.3, // Optional: Add some opacity for better text readability
                        }} className="d-md-none" /> {/* Ensure this div only appears on mobile */}
                    </Col>
                    {/* Image Wrapper for desktop */}
                    <Col md={6} className="how-it-works-image-wrapper d-none d-md-block" style={{
                        backgroundImage: `url(${mapWrapperImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '75vh',
                        margin: 0
                    }}>
                        {/* Additional content can go here if needed */}
                    </Col>
                </Row>
            </Container>
        </div>
            {/* Feature List Section */}
            <div className="feature-list-section" style={{ padding: '30px' }}>
                <Container>
                    {/* Feature List Heading */}
                    <div
                        className="feature-list-header"
                        ref={headerRef}
                        style={{ textAlign: 'left', marginBottom: '40px' }}
                    >
                        <h3 style={{ color: '#67CA1B', fontWeight: 'bold', }}>Effortless Ride-Sharing Process</h3>
                        <p
                            ref={paragraphRef}
                            style={{ color: '#000000', fontSize: 'small' }}
                        >
                            Discover how Transweeft simplifies your commute with these key features.
                        </p>
                    </div>  

                    {/* Feature Images */}
                    <Row className="g-2">
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }} ref={imagesRefs[0]}>
                            <img src={feature1} alt="Feature 1" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }} ref={imagesRefs[1]}>
                            <img src={feature2} alt="Feature 2" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }} ref={imagesRefs[2]}>
                            <img src={feature3} alt="Feature 3" style={{ width: '100%' }} />
                        </Col>
                        <Col xs={6} md={3} className="feature-image" style={{ textAlign: 'center' }} ref={imagesRefs[3]}>
                            <img src={feature4} alt="Feature 4" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Container>
            </div>
             {/* New Testimonial Section */}
            <div className="testimonial-section" style={{ padding: '30px'}}>
                <Container>
                    {/* Testimonial Header */}
                <div className="testimonial-header" style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <h3
                        ref={headerRef}
                        className="typing-animation"
                        style={{ color: '#67CA1B', fontWeight: 'bold' }}
                    >
                        What Our Users Say
                    </h3>
                    <p
                        ref={paragraphRef}
                        className="typing-animation"
                        style={{ color: '#000000', fontSize: 'small' }}
                    >
                        Discover what our users have to say about their experience with Transweeft.
                    </p>
                </div>

                    {/* Testimonial Cards */}
                    <Row className="justify-content-center g-4">
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', 
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={user2}
      alt="User 2"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Temitope Adekunle</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    I rely on Transweeft every day. It’s fast, convenient, and incredibly reliable. I appreciate how easy it is to find a ride, even during peak hours.
    </Card.Text>
  </div>
</Card>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
     padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', 
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={user3}
      alt="User 3"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Chiamaka Eze</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    The app is very intuitive, and the drivers are courteous. I’ve met some great people during shared rides, which makes it all the more enjoyable.
    </Card.Text>
  </div>
</Card>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', 
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={user4}
      alt="User 4"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Blessing Emile</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    I love the transparent pricing and the ability to choose my ride preferences. Transweeft makes everything seamless from start to finish.
    </Card.Text>
  </div>
</Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Key Features Section */}
<div className="key-features-section" style={{ padding: '30px' }}>
    <Container>
        {/* Section Header */}
        <div className="features-header" style={{ textAlign: 'left', marginBottom: '10px' }}>
            <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Key Features</h3>
            <p style={{ color: '#000000', fontSize: 'small' }}>
                Discover the unique features that set Transweeft apart in the urban transportation landscape:
            </p>
        </div>

        {/* Key Features Cards */}
        <Row className="align-items-start">
            {/* First Row: Two Cards */}
            <Col xs={12} md={6} style={{ marginBottom: '20px' }}>
                <div className="card" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left', border: 'none', background: 'none' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontWeight: 'bold' }}>Verified Drivers</h5>
                        <p className="card-text" style={{ fontSize: 'small', marginBottom: '10px' }}>
                            All drivers undergo thorough ID and NIN verification for enhanced safety and security.
                        </p>
                    </div>
                    <img src={keyFeature1} alt="Verified Drivers" style={{ width: '90%', height: 'auto', marginLeft: '15px' }} />
                </div>
            </Col>

            <Col xs={12} md={6} style={{ marginBottom: '20px' }}>
                <div className="card" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left', border: 'none', background: 'none' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontWeight: 'bold' }}>Flexible Scheduling</h5>
                        <p className="card-text" style={{ fontSize: 'small', marginBottom: '10px' }}>
                          Easily find rides that match your schedule with our user-friendly platform.
                        </p>
                    </div>
                    <img src={keyFeature2} alt="Flexible Scheduling" style={{ width: '90%', height: 'auto', marginLeft: '15px' }} />
                </div>
            </Col>
        </Row>

        {/* Second Row: Full Width Card */}
        <Row>
            <Col xs={12}>
                <div className="card" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left', border: 'none', background: 'none' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontWeight: 'bold' }}>Community Connection</h5>
                        <p className="card-text" style={{ fontSize: 'small', marginBottom: '10px' }}>
                        Experience a shared ride environment that promotes social interaction.
                        </p>
                    </div>
                    <img src={keyFeature3} alt="Community Connection" style={{ width: '92%', height: 'auto', marginLeft: '15px' }} />
                </div>
            </Col>
        </Row>
    </Container>
</div>


 <div className="future-growth-section" style={{ padding: '30px' }}>
            <Container>
                {/* Section Header */}
                <div className="features-header" style={{ textAlign: 'left', marginBottom: '10px' }}>
                    <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>Future Growth</h3>
                    <p style={{ color: '#000000', fontSize: 'small' }}>
                    Explore how Transweeft is expanding its horizons and enhancing the commuting experience for users across Nigeria with innovative features and wider accessibility.
                    </p>
                </div>

                {/* Cards Section */}
                <Row>
                    <Col md={4} sm={12} className="mb-4 d-flex">
                        <Card style={{ background: 'linear-gradient(0deg, #BDF990 0%, #C2F998 7%, #FFFFFF 100%)', flex: '1' }}>
                            <Card.Body className="d-flex flex-column">
                                <Card.Title style={{ fontWeight: 'bold', fontSize: 'small' }}>City Expansion</Card.Title>
                                <Card.Text style={{ flex: '1', fontSize: 'small' }}>
                                  Bringing shared commuting to major cities in Nigeria, making transportation more accessible and convenient for a larger audience.
                                </Card.Text>
                               <Link to="/about-us" style={{ textDecoration: 'none' }}>
                <Button 
                    variant="light" 
                    style={{ alignSelf: 'flex-start', padding: '5px 10px', fontSize: '0.8rem' }} 
                >
                    Learn More
                </Button>
            </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} className="mb-4 d-flex">
                        <Card style={{ background: 'linear-gradient(0deg, #BDF990 0%, #C2F998 7%, #FFFFFF 100%)', flex: '1'}}>
                            <Card.Body className="d-flex flex-column">
                                <Card.Title style={{ fontWeight: 'bold', fontSize: 'small' }}>Integrated Public Transport</Card.Title>
                                <Card.Text style={{ flex: '1', fontSize: 'small' }}>
                                Incorporating public transportation options to provide a comprehensive travel solution, combining shared rides with existing transit services.
                                </Card.Text>
                               <Link to="/about-us" style={{ textDecoration: 'none' }}>
                <Button 
                    variant="light" 
                    style={{ alignSelf: 'flex-start', padding: '5px 10px', fontSize: '0.8rem' }} 
                >
                    Learn More
                </Button>
            </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} className="mb-4 d-flex">
    <Card style={{ background: 'linear-gradient(0deg, #BDF990 0%, #C2F998 7%, #FFFFFF 100%)', flex: '1'}}>
        <Card.Body className="d-flex flex-column">
            <Card.Title style={{ fontWeight: 'bold', fontSize: 'small' }}>New Features Coming Soon</Card.Title>
            <Card.Text style={{ flex: '1', fontSize: 'small' }}>
                Stay updated for exciting additions like return trip options and ride history tracking, enhancing the overall user experience and convenience.
            </Card.Text>
            <Link to="/about-us" style={{ textDecoration: 'none' }}>
                <Button 
                    variant="light" 
                    style={{ alignSelf: 'flex-start', padding: '5px 10px', fontSize: '0.8rem' }} 
                >
                    Learn More
                </Button>
            </Link>
        </Card.Body>
    </Card>
</Col>
                </Row>
            </Container>
        </div>
             
             {/* driversTestimonial Section */}
            <div className="testimonial-section" style={{ padding: '30px'}}>
                <Container>
                    {/* Testimonial Header */}
                    <div className="testimonial-header" style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <h3 style={{ color: '#67CA1B', fontWeight: 'bold' }}>What Our Drivers are saying</h3>
                        <p style={{ color: '#000000', fontSize: 'small' }}>
                        Check out some of the positive feedback from our riders who have experienced stress-free and affordable commuting with Transweeft. Join the Transweeft community today!
                        </p>
                    </div>

                    {/* Testimonial Cards */}
                    <Row className="justify-content-center g-4">
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', 
    alignItems: 'flex-start', 
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={driver1}
      alt="driver1"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>David</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    Transweeft has completely changed my daily commute. It's convenient, reliable, and cost-effective!
    </Card.Text>
  </div>
</Card>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', // Use flexbox for layout
    alignItems: 'flex-start', // Align items to the top
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={driver2}
      alt="driver2"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Emily</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
    I never thought commuting could be this easy. Transweeft has made my travel time enjoyable and hassle-free.
    </Card.Text>
  </div>
</Card>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Card
  className="testimonial-card"
  style={{
    padding: '10px',
    backgroundColor: '#F4F4F4',
    display: 'flex', // Use flexbox for layout
    alignItems: 'flex-start', // Align items to the top
    borderRadius: '8px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
  }}
>
  {/* Profile Image */}
  <div style={{ marginRight: '15px' }}>
    <Card.Img
      variant="top"
      src={driver3}
      alt="driver3"
      style={{
        borderRadius: '50%',
        width: '50px',
        height: '50px',
      }}
    />
  </div>

  {/* Text Content */}
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Card.Title style={{ fontWeight: 'bold', margin: 0, fontSize: '0.9rem',paddingTop: '10px' }}>Michael</Card.Title>
    <Card.Text style={{ fontSize: '0.8rem', margin: 0 }}>
  Transweeft has helped me connect with passengers and make extra income while contributing to a greener environment.
    </Card.Text>
  </div>
</Card>
                        </Col>
                    </Row>
                </Container>
            </div>

                  {/* Modal for user interest */}
            <UserInterestModal show={showModal} handleClose={handleCloseModal} /> 


        </div>
    );
};

export default Home;
